import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

export const SubPageHero = styled.div`
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 1fr repeat(10, 1fr) 1fr;
  }
`

export const HeroText = styled.span`
  grid-column: 2 / 3;
  grid-row: 1;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 11;
  }

  h1,
  h2 {
    font-size: 3.6rem;
    line-height: 1.35;
    font-weight: 300;
    color: var(--highlight-blue);
    padding: 25px 0;

    @media ${DEVICE.tabletL} {
      font-size: 4.8rem;
      padding: 50px 0;
    }

    @media ${DEVICE.laptopS} {
      /* Figma: H1 Desktop Light 64 */
      font-size: 6.4rem;
    }
  }
`
