import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { v4 as uuidv4 } from 'uuid'
import { graphql, StaticQuery } from 'gatsby'
import {
  getIsDuringBusinessHours,
  getIsOfflineDate,
} from '../../util/timeDateUtils'

import ChatBot from '../../components/ContentBlocks/Chatbot'

import * as Styled from './styles/Sidebar.styles'

const SidebarBodyContent = ({ data }) => {
  const heading = data.sidebar_header_text ?? ''
  const body = data.body_copy?.richText ?? []

  return (
    <Styled.Sidebar>
      <Styled.SidebarGrid>
        <Styled.TextContainer>
          {heading && <Styled.Title>{heading}</Styled.Title>}
          {body && (
            <Styled.Body>
              <PrismicRichText field={body} />
            </Styled.Body>
          )}
        </Styled.TextContainer>
        <Styled.CircleAccentTop>
          <svg
            width="159"
            height="159"
            viewBox="0 0 159 159"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="79.5"
              cy="79.5"
              r="76.5"
              stroke="#829FE8"
              strokeWidth="6"
            />
          </svg>
        </Styled.CircleAccentTop>
        <Styled.CircleAccentBottom>
          <svg
            width="116"
            height="116"
            viewBox="0 0 116 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="58" cy="58" r="55" stroke="#F29F05" strokeWidth="6" />
          </svg>
        </Styled.CircleAccentBottom>
      </Styled.SidebarGrid>
    </Styled.Sidebar>
  )
}

const SidebarGetInTouch = ({ data, general }) => {
  const {
    ask_a_question__english_link,
    ask_a_question__english_link_text,
    ask_a_question__english_question_text,
    ask_a_question__spanish_link,
    ask_a_question__spanish_link_text,
    ask_a_question__spanish_question_text,
    chat_label,
    chatbot_offline_dates,
    email_address_label,
    email_address,
    hours_of_operation_label,
    is_text_sms_enabled,
    hours_of_operation_days,
    hours_of_operation_times,
    online_days,
    online_times,
    phone_extension_option,
    phone_number_label,
    phone_number,
    texting_phone_number,
    texting_phone_number_label,
  } = general

  const isDuringBusinessHours = getIsDuringBusinessHours()

  const offlineDateArray = chatbot_offline_dates.map(date => date.offline_date)
  const isOfflineDate = getIsOfflineDate(offlineDateArray)

  return (
    <Styled.Sidebar>
      <Styled.SidebarGrid>
        <Styled.TextContainer>
          {!!data.sidebar_header_text && (
            <Styled.Title>{data.sidebar_header_text}</Styled.Title>
          )}
          <Styled.List>
            {email_address_label && email_address && (
              <Styled.Item>
                <Styled.ItemIconContainer>
                  <svg
                    width="25"
                    height="21"
                    viewBox="0 0 25 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 0.545898H2.5C1.125 0.545898 0.0125 1.66796 0.0125 3.03938L0 18.0003C0 19.3717 1.125 20.4937 2.5 20.4937H22.5C23.875 20.4937 25 19.3717 25 18.0003V3.03938C25 1.66796 23.875 0.545898 22.5 0.545898ZM22.5 18.0003H2.5V5.53286L12.5 11.7666L22.5 5.53286V18.0003ZM12.5 9.27308L2.5 3.03938H22.5L12.5 9.27308Z"
                      fill="#1A5DC8"
                    />
                  </svg>
                </Styled.ItemIconContainer>
                <Styled.ItemTextContainer>
                  <Styled.ItemTitle>{email_address_label}</Styled.ItemTitle>
                  <Styled.ItemBodyLink
                    href={`mailto:${email_address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email_address}
                  </Styled.ItemBodyLink>
                </Styled.ItemTextContainer>
              </Styled.Item>
            )}
            {chat_label && isDuringBusinessHours && !isOfflineDate && (
              <Styled.Item>
                <Styled.ItemIconContainer>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 0H2.5C1.125 0 0.0125 1.12207 0.0125 2.49348L0 24.9348L5 19.9479H22.5C23.875 19.9479 25 18.8258 25 17.4544V2.49348C25 1.12207 23.875 0 22.5 0ZM5 8.72719H20V11.2207H5V8.72719ZM15 14.9609H5V12.4674H15V14.9609ZM20 7.48044H5V4.98696H20V7.48044Z"
                      fill="#1A5DC8"
                    />
                  </svg>
                </Styled.ItemIconContainer>
                <Styled.ItemTextContainer>
                  <ChatBot
                    chatLabel={chat_label}
                    onlineDays={online_days}
                    onlineTimes={online_times}
                  />
                </Styled.ItemTextContainer>
              </Styled.Item>
            )}
            {ask_a_question__english_question_text &&
              ask_a_question__english_link_text &&
              ask_a_question__english_link && (
                <Styled.Item>
                  <Styled.ItemIconContainer>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.4875 0C5.5875 0 0 5.5854 0 12.4674C0 19.3494 5.5875 24.9348 12.4875 24.9348C19.4 24.9348 25 19.3494 25 12.4674C25 5.5854 19.4 0 12.4875 0ZM21.15 7.48044H17.4625C17.0625 5.92202 16.4875 4.42593 15.7375 3.04205C18.0375 3.82749 19.95 5.42332 21.15 7.48044ZM12.5 2.54335C13.5375 4.03944 14.35 5.69761 14.8875 7.48044H10.1125C10.65 5.69761 11.4625 4.03944 12.5 2.54335ZM2.825 14.9609C2.625 14.163 2.5 13.3277 2.5 12.4674C2.5 11.6072 2.625 10.7718 2.825 9.97393H7.05C6.95 10.7968 6.875 11.6196 6.875 12.4674C6.875 13.3152 6.95 14.138 7.05 14.9609H2.825ZM3.85 17.4544H7.5375C7.9375 19.0128 8.5125 20.5089 9.2625 21.8928C6.9625 21.1073 5.05 19.524 3.85 17.4544ZM7.5375 7.48044H3.85C5.05 5.41085 6.9625 3.82749 9.2625 3.04205C8.5125 4.42593 7.9375 5.92202 7.5375 7.48044ZM12.5 22.3915C11.4625 20.8954 10.65 19.2372 10.1125 17.4544H14.8875C14.35 19.2372 13.5375 20.8954 12.5 22.3915ZM15.425 14.9609H9.575C9.4625 14.138 9.375 13.3152 9.375 12.4674C9.375 11.6196 9.4625 10.7843 9.575 9.97393H15.425C15.5375 10.7843 15.625 11.6196 15.625 12.4674C15.625 13.3152 15.5375 14.138 15.425 14.9609ZM15.7375 21.8928C16.4875 20.5089 17.0625 19.0128 17.4625 17.4544H21.15C19.95 19.5115 18.0375 21.1073 15.7375 21.8928ZM17.95 14.9609C18.05 14.138 18.125 13.3152 18.125 12.4674C18.125 11.6196 18.05 10.7968 17.95 9.97393H22.175C22.375 10.7718 22.5 11.6072 22.5 12.4674C22.5 13.3277 22.375 14.163 22.175 14.9609H17.95Z"
                        fill="#1A5DC8"
                      />
                    </svg>
                  </Styled.ItemIconContainer>
                  <Styled.ItemTextContainer>
                    <Styled.ItemTitle>
                      {ask_a_question__english_question_text}
                    </Styled.ItemTitle>
                    <Styled.ItemBodyLink href={ask_a_question__english_link}>
                      {ask_a_question__english_link_text}
                    </Styled.ItemBodyLink>
                    <Styled.ItemTitle>
                      {ask_a_question__spanish_question_text}
                    </Styled.ItemTitle>
                    <Styled.ItemBodyLink href={ask_a_question__spanish_link}>
                      {ask_a_question__spanish_link_text}
                    </Styled.ItemBodyLink>
                  </Styled.ItemTextContainer>
                </Styled.Item>
              )}
            {hours_of_operation_label && (
              <Styled.Item>
                <Styled.ItemIconContainer>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 12.4674C0 5.5854 5.5875 0 12.4875 0C19.4 0 25 5.5854 25 12.4674C25 19.3494 19.4 24.9348 12.4875 24.9348C5.5875 24.9348 0 19.3494 0 12.4674ZM2.5 12.4674C2.5 17.978 6.975 22.4413 12.5 22.4413C18.025 22.4413 22.5 17.978 22.5 12.4674C22.5 6.95681 18.025 2.49348 12.5 2.49348C6.975 2.49348 2.5 6.95681 2.5 12.4674ZM11.25 6.2337H13.125V12.7791L18.75 16.1079L17.8125 17.6414L11.25 13.7142V6.2337Z"
                      fill="#1A5DC8"
                    />
                  </svg>
                </Styled.ItemIconContainer>
                <Styled.ItemTextContainer>
                  <Styled.ItemTitle>
                    {hours_of_operation_label}
                  </Styled.ItemTitle>
                  <Styled.ItemBody>
                    {hours_of_operation_days ? (
                      <>
                        {hours_of_operation_days}
                        <br />
                      </>
                    ) : null}
                    {hours_of_operation_times ? hours_of_operation_times : null}
                  </Styled.ItemBody>
                </Styled.ItemTextContainer>
              </Styled.Item>
            )}
            {phone_number_label && phone_number && (
              <Styled.Item>
                <Styled.ItemIconContainer>
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.25 15.6624C19.6875 15.6624 18.1875 15.413 16.7875 14.9517C16.35 14.8146 15.8625 14.9143 15.5125 15.2509L12.7625 17.9938C9.225 16.1985 6.325 13.3185 4.525 9.79023L7.275 7.03493C7.625 6.69831 7.725 6.21208 7.5875 5.77573C7.125 4.37938 6.875 2.88329 6.875 1.32486C6.875 0.639158 6.3125 0.078125 5.625 0.078125H1.25C0.5625 0.078125 0 0.639158 0 1.32486C0 13.0318 9.5125 22.5194 21.25 22.5194C21.9375 22.5194 22.5 21.9584 22.5 21.2727V16.9091C22.5 16.2234 21.9375 15.6624 21.25 15.6624ZM11.25 0.078125V12.5455L15 8.8053H22.5V0.078125H11.25Z"
                      fill="#1A5DC8"
                    />
                  </svg>
                </Styled.ItemIconContainer>
                <Styled.ItemTextContainer>
                  <Styled.ItemTitle>{phone_number_label}</Styled.ItemTitle>
                  <Styled.ItemBody>
                    <Styled.ItemBodyLink href={`tel:${phone_number}`}>
                      {phone_number}
                    </Styled.ItemBodyLink>
                    <br />
                    {phone_extension_option ? phone_extension_option : null}
                  </Styled.ItemBody>
                </Styled.ItemTextContainer>
              </Styled.Item>
            )}
            {texting_phone_number_label &&
              texting_phone_number &&
              isDuringBusinessHours &&
              !isOfflineDate &&
              is_text_sms_enabled && (
                <Styled.Item>
                  <Styled.ItemIconContainer>
                    <svg
                      width="17"
                      height="28"
                      viewBox="0 0 17 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.125 0.116211H3.125C1.4 0.116211 0 1.51256 0 3.23306V24.4276C0 26.1481 1.4 27.5445 3.125 27.5445H13.125C14.85 27.5445 16.25 26.1481 16.25 24.4276V3.23306C16.25 1.51256 14.85 0.116211 13.125 0.116211ZM8.125 26.2978C7.0875 26.2978 6.25 25.4624 6.25 24.4276C6.25 23.3929 7.0875 22.5575 8.125 22.5575C9.1625 22.5575 10 23.3929 10 24.4276C10 25.4624 9.1625 26.2978 8.125 26.2978ZM13.75 21.3108H2.5V3.85643H13.75V21.3108Z"
                        fill="#1A5DC8"
                      />
                    </svg>
                  </Styled.ItemIconContainer>
                  <Styled.ItemTextContainer>
                    <Styled.ItemTitle>
                      {texting_phone_number_label}
                    </Styled.ItemTitle>
                    <Styled.ItemBody>
                      <Styled.ItemBodyLink href={`sms:${texting_phone_number}`}>
                        {texting_phone_number}
                      </Styled.ItemBodyLink>
                    </Styled.ItemBody>
                  </Styled.ItemTextContainer>
                </Styled.Item>
              )}
          </Styled.List>
        </Styled.TextContainer>
        <Styled.CircleAccentTop>
          <svg
            width="159"
            height="159"
            viewBox="0 0 159 159"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="79.5"
              cy="79.5"
              r="76.5"
              stroke="#829FE8"
              strokeWidth="6"
            />
          </svg>
        </Styled.CircleAccentTop>
        <Styled.CircleAccentBottom>
          <svg
            width="116"
            height="116"
            viewBox="0 0 116 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="58" cy="58" r="55" stroke="#F29F05" strokeWidth="6" />
          </svg>
        </Styled.CircleAccentBottom>
      </Styled.SidebarGrid>
    </Styled.Sidebar>
  )
}

const SidebarGoogleAd = () => (
  <Styled.Sidebar>
    <Styled.SidebarGrid>
      <Styled.TextContainer>
        <Styled.Title>Google Ad will replace this block</Styled.Title>
      </Styled.TextContainer>
      <Styled.CircleAccentTop>
        <svg
          width="159"
          height="159"
          viewBox="0 0 159 159"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="79.5"
            cy="79.5"
            r="76.5"
            stroke="#829FE8"
            strokeWidth="6"
          />
        </svg>
      </Styled.CircleAccentTop>
      <Styled.CircleAccentBottom>
        <svg
          width="116"
          height="116"
          viewBox="0 0 116 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="58" cy="58" r="55" stroke="#F29F05" strokeWidth="6" />
        </svg>
      </Styled.CircleAccentBottom>
    </Styled.SidebarGrid>
  </Styled.Sidebar>
)

const SidebarRenderer = ({ block, general }) => {
  // return <pre>{JSON.stringify(general, false, 2)}</pre>
  switch (block.slice_type) {
    case 'body_content':
      return <SidebarBodyContent data={block.primary} />
    case 'get_in_touch':
      return <SidebarGetInTouch data={block.primary} general={general} />
    case 'google_ad':
      return <SidebarGoogleAd />
    default:
      return null
  }
}

const SIDEBAR_QUERY = graphql`
  {
    prismicGeneralSiteSettings {
      data {
        email_address_label
        email_address
        phone_number_label
        phone_number
        phone_extension_option
        texting_phone_number_label
        texting_phone_number
        chat_label
        chatbot_offline_dates {
          offline_date
        }
        ask_a_question__english_question_text
        ask_a_question__english_link_text
        ask_a_question__english_link
        ask_a_question__spanish_question_text
        ask_a_question__spanish_link_text
        ask_a_question__spanish_link
        hours_of_operation_label
        hours_of_operation_days
        hours_of_operation_times
        is_text_sms_enabled
        online_days
        online_times
      }
    }
  }
`

const Sidebar = ({ sidebarData }) => (
  <StaticQuery
    query={`${SIDEBAR_QUERY}`}
    render={data => {
      const doc = data.prismicGeneralSiteSettings.data
      if (!doc) {
        return null
      }
      return (
        // <pre>{JSON.stringify(sidebarData, false, 2)}</pre>
        sidebarData &&
        sidebarData.map(block => (
          <SidebarRenderer key={block.id} block={block} general={doc} />
        ))
      )
    }}
  />
)

export default Sidebar
