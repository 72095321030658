import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'

export const ChatFormContainer = styled.div`
  background-color: var(--content-background-dark-blue);
  bottom: 0;
  display: flex;
  left: 0;
  flex-direction: column;
  padding: 30px 30px 50px;
  position: fixed;
  right: 0;

  @media ${DEVICE.tabletL} {
    left: auto;
    min-width: 400px;
  }
`

export const ChatFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 30px;
`

export const ChatFormTitle = styled.h4`
  color: var(--content-background-light);
  font-size: 1.8rem;
  line-height: 1.38em;
`

export const ChatFormCloseButton = styled.button`
  background: none;
  color: var(--content-background-light);
  display: block;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.357;
`

export const ChatForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const ChatFormInput = styled.input`
  display: block;
  font-family: 'Open Sans', Tahoma, sans-serif;
  font-size: 1.6rem;
  line-height: 1.357;
  margin-bottom: 10px;
  padding: 9px 10px 8px;
`

export const ChatFormErrorMessage = styled.p`
  color: var(--graphic-accent-orange-1);
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.357;
  margin: 0 0 20px;
`

export const ChatFormSubmitButton = styled.button`
  background: var(--content-background-light);
  color: var(--content-background-dark-blue);
  display: block;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.357;
  padding: 14px;
  text-align: center;

  &:hover,
  &:focus {
    background: var(--graphic-accent-purple-2);
    color: var(--content-background-light);
  }
`

export const ChatContainer = styled.div`
  height: ${props => (props.isVisible ? 'auto' : 0)};
  min-height: ${props => (props.isVisible ? '350px' : 0)};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  overflow: hidden;

  iframe {
    min-height: 350px;
  }
`

export const ChatButton = styled.button`
  background: none;
  border: 0;
  color: var(--content-background-dark-blue);
  font-family: 'Open Sans', Tahoma, sans-serif;
  font-size: 1.8rem;
  line-height: 1.38em;
  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover,
  &:active {
    color: var(--highlight-blue);
  }

  @media ${DEVICE.laptopS} {
    font-size: 2rem;
    line-height: 1.35em;
  }
`
