import React from 'react'

import * as Styled from './styles/SubPageHero.styles'
import { RegionContainer } from '../styles/Utility.styles'

const SubPageHero = ({ heroText }) => (
  <RegionContainer>
    <Styled.HeroText>
      <h1>{heroText.text}</h1>
    </Styled.HeroText>
  </RegionContainer>
)

export default SubPageHero
