import React from 'react'
import { Script } from 'gatsby'

import * as Styled from './styles/Chatbot.styles'

const ChatBot = ({ chatLabel }) => {
  const fiveNineConfig = {
    restAPI: 'https://app.five9.com',
    rootUrl: 'https://app.five9.com/consoles/',
    tenant: 'National Psoriasis Foundation',
    profiles: 'Chat',
    title: 'Technical Support',
    showProfiles: true,
  }

  const openChat = () => {
    const rootUrl = `${fiveNineConfig.rootUrl}ChatConsole`
    let url = rootUrl
    let elementCounter = 0

    for (const key in fiveNineConfig) {
      url = url + (elementCounter++ === 0 ? '?' : '&')
      // eslint-disable-next-line no-prototype-builtins
      if (fiveNineConfig.hasOwnProperty(key)) {
        url = `${url}${key}=${fiveNineConfig[key]}`
      }
    }
    const chatUrl = encodeURI(url)
    const props =
      'width=600,height=550,location=no,menubar=no,resizable=yes,scrollbars=no,status=no,titlebar=no,toolbar=no'
    window.open(chatUrl, 'Five9Chat', props)
    return false
  }

  return (
    <>
      <Script src="https://app.five9.com/consoles/SocialWidget/five9-social-widget.min.js" />
      <Styled.ChatButton onClick={openChat}>{chatLabel}</Styled.ChatButton>
    </>
  )
}

export default ChatBot
